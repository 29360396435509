import { TableCell, Typography, useTheme } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useContext } from "react";
import { DashboardContext } from "../../../contexts/DashboardContext/DashboardContext";
import { TableColumn } from "../Dashboard";
import { LogVirtuosoTableData } from "../Utils/tableDataFetch";
import CustomCheckbox from "./CustomCheckbox";
import StatusBox from "./StatusBox";

interface TableRowContentProps {
  row: LogVirtuosoTableData;
  columns: TableColumn[];
}

/*
 * This component is used to display the table row content, which contains the data for each row.
 * It is used in the DashboardTable component to define the table rows.
 */
export const TableRowContent = ({ row, columns }: TableRowContentProps) => {
  const { selectedRows, setSelectedRows } = useContext(DashboardContext);
  const theme = useTheme();

  const handleRowSelect = (rowId: string) => {
    setSelectedRows((prevSelectedRows: Set<string>) => {
      const newSelectedRows = new Set(prevSelectedRows);
      if (newSelectedRows.has(rowId)) {
        newSelectedRows.delete(rowId);
      } else {
        newSelectedRows.add(rowId);
      }
      return newSelectedRows;
    });
  };

  const formatDateAndTime = (dayjsDate: Dayjs) => {
    return (
      <>
        <Typography component="span">
          {dayjsDate.isValid()
            ? dayjsDate.format("MM/DD/YYYY").toString() + ","
            : "N/A"}
        </Typography>
        <br />
        <Typography component="span">
          {dayjsDate.isValid() ? dayjsDate.format("hh:mm:ss A").toString() : ""}
        </Typography>
      </>
    );
  };

  return (
    <>
      <TableCell
        sx={{
          width: "10px",
          backgroundColor: (theme) =>
            selectedRows.has(row.id)
              ? theme.palette.mode === "dark"
                ? "#333"
                : "#FFF6E8"
              : theme.palette.mode === "dark"
              ? "#555"
              : "white",
          borderBottom: (theme) =>
            selectedRows.has(row.id)
              ? theme.palette.mode === "dark"
                ? "1px solid black"
                : "1px solid #FFBA4F"
              : "default",
          pt: 3.5,
        }}
      >
        <CustomCheckbox
          checked={selectedRows.has(row.id)}
          onChange={() => handleRowSelect(row.id)}
          name="rowSelect"
          indeterminate={false}
        />
      </TableCell>
      {columns.map((column) => {
        let displayValue = row[column.dataKey];
        if (column.dataKey === "documentId") {
          displayValue = (
            <>
              <Typography sx={{ fontWeight: "bold", mb: 0 }}>
                {row.documentId}
              </Typography>
              <br />
              <Typography
                sx={{
                  color:
                    theme.palette.mode === "dark" ? "lightgrey" : "#858585",
                  fontSize: "12px",
                  mt: -1,
                }}
              >
                {row.id}
              </Typography>
            </>
          );
        } else if (
          column.dataKey === "completedAt" ||
          column.dataKey === "submittedAt"
        ) {
          const date = dayjs(row[column.dataKey]);
          displayValue = formatDateAndTime(date);
        } else if (column.dataKey === "status") {
          const statusValue = row[column.dataKey];
          let status = "error";

          if (typeof statusValue === "string") {
            const lowerCaseStatus = statusValue.toLowerCase();
            if (lowerCaseStatus === "succeeded" || lowerCaseStatus === "n/a") {
              status = lowerCaseStatus;
            }
          }

          displayValue = (
            <StatusBox
              status={status as "error" | "succeeded" | "n/a"}
              statusValue={statusValue}
            />
          );
        } else if (column.dataKey === "appData") {
          displayValue = JSON.stringify(row.appData);
          if (displayValue === "null") {
            displayValue = "N/A";
          }
        } else {
          if (displayValue == null) {
            displayValue = "N/A";
          }
        }
        return (
          console.log(column.dataKey),
          console.log("displayValue: ", displayValue),
          (
            <TableCell
              key={column.dataKey}
              align={column.numeric ? "right" : "left"}
              sx={{
                minWidth: column.width,
                backgroundColor: (theme) =>
                  selectedRows.has(row.id)
                    ? theme.palette.mode === "dark"
                      ? "#333"
                      : "#FFF6E8"
                    : theme.palette.mode === "dark"
                    ? "#555"
                    : "white",
                borderBottom: (theme) =>
                  selectedRows.has(row.id)
                    ? theme.palette.mode === "dark"
                      ? "1px solid black"
                      : "1px solid #FFBA4F"
                    : "default",
                pt: 4,
                pb: 4,
                pl: 2,
                pr: 2,
              }}
            >
              {column.formatter ? column.formatter(displayValue) : displayValue}
            </TableCell>
          )
        );
      })}
    </>
  );
};
